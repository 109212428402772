import {put,fork,call,take} from 'redux-saga/effects';

import * as format from '../utils/format';

import Types from '../actions/types';
import {getApiUrl} from '../utils/helper'
import AccessTokenService from '../services/access-token-service';
import MarkitApiService from '../services/markit-api-service';
import writeToDebug from '../utils/writeToDebug';
import {getApiTypes} from './getApiTypes';
import {isDebugInfoOn} from '../utils/pathUtils';
import {setAccessToken} from '../actions/deltaOneWidgetActions';

// Use a 'watcher/worker' pattern to listen for and handle redux Actions
export default () => {
	let api = null;
	let url = null;
	let platformApi = null;

	function getApiFunction(httpMethod) {
		let apiType;

		switch (httpMethod) {
			case 'POST':
				apiType = api.postData;
				break;
			case 'PUT':
				apiType = api.putData;
				break;
			case 'DELETE':
				apiType = api.deleteData;
				break;
			case 'GET':
			default:
				apiType = api.getData;
		}

		return apiType;
	}

	function getPlatformApiFunction (httpMethod) {
        let apiType
    
        switch (httpMethod) {
          case 'POST':
            apiType = platformApi.postData
            break
          case 'PUT':
            apiType = platformApi.putData
            break
          case 'DELETE':
            apiType = platformApi.deleteData
            break
          case 'GET':
          default:
            apiType = platformApi.getData
        }
    
        return apiType
    }

	// Worker
	function* worker(action) {
		let accessToken;

		//const localAccessTokenQueryString = format.getQueryString("access_token")
		platformApi = {};
		const localAccessTokenQueryString = format.getQueryString("access_token")
		const localAccessToken = localAccessTokenQueryString;
		accessToken = AccessTokenService.retrieve(localAccessToken);
		
		let apiDetail = {
			apigeeUrl: getApiUrl(),
			accessToken: accessToken
		};

		platformApi = yield MarkitApiService.create(apiDetail.apigeeUrl, apiDetail.accessToken);

		if (!api) {
			api = {};
			const localAccessTokenQueryString = format.getQueryString("access_token")
			const localAccessToken = localAccessTokenQueryString
			accessToken = AccessTokenService.retrieve(localAccessToken);
			let apiDetails = {
				url: getApiUrl(),
				accessToken: accessToken
			}		
			localStorage.setItem('access_token', apiDetails.accessToken)
			accessToken = apiDetails.accessToken;

			url = apiDetails.url;
			api = yield MarkitApiService.create(url, apiDetails.accessToken);
			
			yield put(setAccessToken(accessToken));
		}
		
		let demoToken = "";
		const host = window.location.host;
		// if (host.indexOf('localhost') > -1 ) { // Running DEV site with demo-token
		// 	demoToken = action.endPoint.indexOf("?") > -1 ? "&access_token=demo-token" : "?access_token=demo-token"
		// }
		let endPoint = action.endPoint + demoToken;
		const parameters = action.params;
		const successAction = action.onSuccess;
		const failureAction = action.onFailure ;
		const httpMethod = action.ajaxType;

		let apiType = getApiFunction(httpMethod);

		if (action.type === Types.API_REQUEST_SUCCESS_GET_CLIENTS_ALL_API) {
            apiType = getPlatformApiFunction(httpMethod);
            endPoint = action.endPoint;
        }

		try {
			let response;
			let x;
			if (action.type === Types.GET_URLBASE_API) {
				response = {
					data: {
						url: url
					},
					ok: true
				};
			} else {
				if (action.type === Types.API_REQUEST_DECODE_API) {
					response = yield parameters.key.map(p =>
						call(apiType, endPoint, p)
					);
					isDebugInfoOn() &&
					writeToDebug(response[0]);
				} else {
					response = yield call(apiType, endPoint, parameters);
					isDebugInfoOn() &&
					writeToDebug(response);
				}

			}

			if (response.ok) {
				// 'data' keys the entire response object... always.
				yield put(successAction(response.data));
			} else if (action.type === Types.API_REQUEST_DECODE_API) {
				yield put(successAction(response));
			} else {
				yield put(failureAction(response));
			}
		} catch (error) {
			yield put(failureAction(error));
		}
	}

	// Watcher
	function* watcher() {
		const typesArray = getApiTypes();
		while (true) {
			const action = yield take(typesArray);
			yield fork(worker, action);
		}
	}

	return {
		watcher
	};
};