import { isDebugInfoOn } from '../../utils/pathUtils';

export default request => {
    if (isDebugInfoOn()) {
		// params don't work on POST and PUT requests; we'll have to add the query manually
		if (request.method.toLowerCase() === 'post' || request.method.toLowerCase() === 'put') {
			request.url = request.url.indexOf('?') === -1
				? `${request.url}?..showdebugdata..=on`
				: `${request.url}&..showdebugdata..=on`;
		} else {
			request.params['..showDebugData..'] = 'on';
		}
	}
};
