import Types from './types';

const setAccessToken = accessToken => ({
	type: Types.SET_ACCESS_TOKEN,
	accessToken: accessToken
});

const receiveSuccessGetEntitlementApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_ENTITLEMENT,
	response
});

const receivefailureGetEntitlementApi = response => ({
	type: Types.RECEIVE_FAILURE_GET_ENTITLEMENT,
	response
});

const requestGetEntitlement = (etpTicker) => ({
	type: Types.API_REQUEST_GET_ENTITLEMENT_API,
	endPoint: '/getDecision?decisionInputs={ "Symbol":"'+etpTicker+'"}',
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetEntitlementApi,
	onFailure: receivefailureGetEntitlementApi
});

const receiveSuccessGetClientStyleApi = response => ({
	type: Types.RECEIVE_SUCCESS_GETCLIENTSTYLE,
	response
});

const requestGetClientStyle = (userTierId) => ({
	type: Types.API_REQUEST_GETCLIENTSTYLE_API,
	endPoint: '/getClientStyle',
	params: {userTierId: userTierId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetClientStyleApi
});

const receiveSuccessGetClientsApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_CLIENTS,
	response
});

const requestGetClientsApi = (userTierId) => ({
	type: Types.API_REQUEST_SUCCESS_GET_CLIENTS_API,
	endPoint: '/getClientStyle',
	params: { userTierId:userTierId },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetClientsApi
});
const receiveSuccessGetClientsAllApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_CLIENTS_ALL,
	response
});

const requestGetClientsAllApi = () => ({
	type: Types.API_REQUEST_SUCCESS_GET_CLIENTS_ALL_API,
	endPoint: '/getClientStyle',
	params: { userTierId:-1 },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetClientsAllApi
});
const requestGetEtfHistoricalData = (etpTicker, premiumDiscountConfig) => ({
	type: Types.API_REQUEST_GETETFHISTORICALDATA_API,
	endPoint: '/getEtfData?etpTicker=' + etpTicker + '&periodType=1&isCountRequired=0&premiumDiscountConfig=' + premiumDiscountConfig,
	ajaxType: 'GET',
    onSuccess: receiveSuccessGetEtfHistoricalData
});

const receiveSuccessGetEtfHistoricalData = response => ({
	type: Types.RECEIVE_SUCCESS_GETETFHISTORICALDATA,
	response
});

const requestGetEtfPrevCalenderYearData = (etpTicker, premiumDiscountConfig) => ({
	type: Types.API_REQUEST_GETETFPREVCALANDERYEARDATA_API,
	endPoint: '/getEtfData?etpTicker=' + etpTicker + '&periodType=2&isCountRequired=1&premiumDiscountConfig=' + premiumDiscountConfig,
	ajaxType: 'GET',
    onSuccess: receiveSuccessGetEtfPrevCalenderYearData
});

const receiveSuccessGetEtfPrevCalenderYearData = response => ({
	type: Types.RECEIVE_SUCCESS_GETETFPREVCALANDERYEARDATA,
	response
});

const requestGetEtfPrevQuarterData = (etpTicker, premiumDiscountConfig) => ({
	type: Types.API_REQUEST_GETETFPREVQUARTERDATA_API,
	endPoint: '/getEtfData?etpTicker=' + etpTicker + '&periodType=3&isCountRequired=2&premiumDiscountConfig=' + premiumDiscountConfig,
	ajaxType: 'GET',
    onSuccess: receiveSuccessGetEtfPrevQuarterData
});

const receiveSuccessGetEtfPrevQuarterData = response => ({
	type: Types.RECEIVE_SUCCESS_GETETFPREVQUARTERDATA,
	response
});

// getEtfBidAskData
const requestGetEtfBidAskData = (etpTicker) => ({
	type: Types.API_REQUEST_GETETFBIDASKDATA_API,
	endPoint: '/getEtfBidAskData?etpTicker=' + etpTicker,
	ajaxType: 'GET',
    onSuccess: receiveSuccessGetEtfBidAskData
});

const receiveSuccessGetEtfBidAskData = response => ({
	type: Types.RECEIVE_SUCCESS_GETETFBIDASKDATA,
	response
});

const requestEtpUserData = () => ({
	type: Types.API_REQUEST_ETP_USER_DATA_API,
	endPoint: '/userData',
	ajaxType: 'GET',
	onSuccess: recieveSuccessEtpUserDataApi
})

const recieveSuccessEtpUserDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_ETP_USER_DATA,
	response
})

export {
	setAccessToken,
	requestGetClientsApi,
	requestGetClientsAllApi,
	receiveSuccessGetClientsApi,
	receiveSuccessGetClientsAllApi,
    requestGetClientStyle,
    requestGetEtfHistoricalData,
    receiveSuccessGetEtfHistoricalData,
    requestGetEtfPrevCalenderYearData,
    receiveSuccessGetEtfPrevCalenderYearData,
    requestGetEtfPrevQuarterData,
	receiveSuccessGetEtfPrevQuarterData,
	requestGetEtfBidAskData,
	receiveSuccessGetEtfBidAskData,
	requestGetEntitlement,
	requestEtpUserData,
	recieveSuccessEtpUserDataApi
};