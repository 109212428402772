import React from 'react';
import * as HomePageStyle from '../../styles/HomePage.scss'
import Chart from '../Chart'
import DesktopChart from '../DesktopChart'
import * as format from '../../utils/format'
import {getToken} from '../../utils/helper'
import {DISCOUNT_IN_PERCENTAGE} from '../../constants'
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(), 
      tickerValue: "TMF",  
      clientState: [],
      prevCalenderYearData: {},
      currentCalenderYearQuarterData: {},
      etfBidAskData: {},
      entitle: 'default',
      isTokenExpire: false,
	    userTier: "",
	    premiumDiscountConfig:""
    };
    this.defaultDash = '--';
    this.isChange= false;
  }

  addStyle(styles) {
    /* Create style document */
    var css = document.createElement('style');
    css.type = 'text/css';
    css.id= 'dynamic-css-api'

    if (css.styleSheet)
      css.styleSheet.cssText = styles;
    else
      css.appendChild(document.createTextNode(styles));

    /* Append style to the tag name */
    document.getElementsByTagName("head")[0].appendChild(css);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorCode && nextProps.errorCode !== 403) {
      if (!this.state.isTokenExpire) {
       // Get Client Style
       this.props.requestGetClientStyleApi();
       
      
       
        // Get ETF Bid/Ask Data
        this.props.requestGetEtfBidAskData(this.props.params.ticker);
        this.setState({ isTokenExpire: true });
     }
      
     if(nextProps.etpUserData && nextProps.etpUserData.userTier) {
       this.setState({ userTier: nextProps.etpUserData.userTier });
       localStorage.setItem('user_Tier', nextProps.etpUserData.userTier);
     }
     if(nextProps.entitle !== this.props.entitle) { 
       let entitlement = nextProps.entitle;   
        this.setState({
       entitle:entitlement.Decision
     });
   }
    // Client Style
    if (nextProps.clientState !== this.props.clientState) {
      let newClientState = nextProps.clientState;
      if (newClientState.length > 0 && newClientState[0].StyleConfig) {
		  this.addStyle(newClientState[0].StyleConfig);
		  // Get ETF Data - Historical, Calender, Quarter
		  this.props.requestGetEtfHistoricalData(this.props.params.ticker, newClientState[0].PremiumDiscountConfig);
		  this.props.requestGetEtfPrevCalenderYearData(this.props.params.ticker, newClientState[0].PremiumDiscountConfig);
		  this.props.requestGetEtfPrevQuarterData(this.props.params.ticker, newClientState[0].PremiumDiscountConfig);
		  this.setState({ premiumDiscountConfig: newClientState[0].PremiumDiscountConfig });
      }
    }

    // Prev Calender Year
    if (nextProps.prevCalenderYearData !== this.props.prevCalenderYearData) {
      this.setState({
        prevCalenderYearData: this.handleDiscPremData(nextProps.prevCalenderYearData)
      })
    }

    // Prev Quarter
    if (nextProps.currentCalenderYearQuarterData !== this.props.currentCalenderYearQuarterData) {
      this.setState({
        currentCalenderYearQuarterData: this.handleDiscPremData(nextProps.currentCalenderYearQuarterData)
      })
    }

    // ETF Bid Ask Data
    if (nextProps.etfBidAskData !== this.props.etfBidAskData) {
      this.setState({
        etfBidAskData: this.handleETFBidAskData(nextProps.etfBidAskData)
      })
    }
  } else if (nextProps.errorCode && this.state.isTokenExpire) {
      this.setState({ isTokenExpire: false });
      getToken(parseInt( localStorage.getItem('user_Tier')));
      this.props.requestGetEntitlement(this.props.params.ticker.toUpperCase());
    }
      if(!localStorage.getItem('access_token') && localStorage.getItem('user_Tier')){
        getToken(parseInt(localStorage.getItem('user_Tier')));
     }
    }
  

  componentWillMount() { 
    //Get Entitlement   
    this.props.requestGetEntitlement(this.props.params.ticker.toUpperCase());  
    this.props.requestEtpUserData();
  }      

  componentWillUnmount(){
    this.isChange=false;
  }
  componentDidUpdate(){    
    if (this.isChange){
    const docHeight = document.documentElement.getBoundingClientRect().height;
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(JSON.stringify({ docHeight }), '*' );
  }
}

  /* Set the style */
  componentDidMount() {
    
    if (this.props.params && this.props.params.ticker) {
      this.setState({ tickerValue: this.props.params.ticker.toUpperCase() });
    }

    if(format.getQueryString("access_token")){
      localStorage.setItem('access_token', format.getQueryString("access_token"));
    }
   this.isChange= true;
  }

  /**
   * Process the data for the zero condition
   * @param {object} data data from the egtETFData API
   */
  handleDiscPremData = (data) => {
    return {
      discTradedDays: data.discTradedDays === 0 ? this.defaultDash: data.discTradedDays,
      premiumTradedDays: data.premiumTradedDays === 0 ? this.defaultDash: data.premiumTradedDays,
      currentYearAllQuaterTradedDays: data.currentYearAllQuaterTradedDays,
      year: data.year
    };
  }

  /**
   * Process the data for the zero condition
   * @param {object} data data from the GetEtfBidAskData API
   */
  handleETFBidAskData = (data) => {
    if (data && data.length > 0) {
      return {
        AsAtDate: format.isNullValue(data[0].AsAtDate) ? this.defaultDash: format.formatOADate(data[0].AsAtDate,'MM/DD/YYYY'),
        BidAskSpreadPct: format.isNullValue(data[0].BidAskSpreadPct) ? this.defaultDash: (data[0].BidAskSpreadPct).toFixed(2) + '%',
        ClosingMarketPrice: format.isNullValue(data[0].ClosingMarketPrice) ? this.defaultDash: (data[0].ClosingMarketPrice).toFixed(2),
        EtpTicker: format.isNullValue(data[0].EtpTicker) ? this.defaultDash: data[0].EtpTicker,
        NAV: format.isNullValue(data[0].NAV) ? this.defaultDash: (data[0].NAV).toFixed(2),
        PremiumDiscountBps: format.isNullValue(data[0].PremiumDiscountPct) ? this.defaultDash: (data[0].PremiumDiscountPct).toFixed(2),
      };
    } else {
      return {
        AsAtDate: this.defaultDash,
        BidAskSpreadPct: this.defaultDash,
        ClosingMarketPrice: this.defaultDash,
        EtpTicker: this.defaultDash,
        NAV: this.defaultDash,
        PremiumDiscountBps: this.defaultDash,
      };  
    }
  }

  renderQuarterYearHeading = () => {
    let heading;
    if (this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays && 
        this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays.length > 0) {
      heading = <th colSpan={this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays.length}><span className={"heading-color"}>{this.state.currentCalenderYearQuarterData.year}</span></th>;
    }
    return heading;
  }

  renderPremiumConfig = () => {
    let config;
    if (this.state.premiumDiscountConfig !== '' && this.state.premiumDiscountConfig !== DISCOUNT_IN_PERCENTAGE) {
      config = '(' + this.state.premiumDiscountConfig + ')';
    }
    return config;
  }

  renderPremiumDiscount = () => {
    let discount = this.state.etfBidAskData.PremiumDiscountBps;
    if (this.state.premiumDiscountConfig == DISCOUNT_IN_PERCENTAGE) {
      discount = (discount / 100).toFixed(2);
      discount = discount + '%';
    }
    return discount;
  }

  shouldPrevCalenderDataRender = () => {
    return this.state.currentCalenderYearQuarterData && this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays && 
    this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays.length > 0 &&
    (this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays[0].name.includes("*") || 
      this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays[0].name === "Since Inception");
  }

  showDisclaimer = () => {
    if( this.state.currentCalenderYearQuarterData && 
        this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays && 
       	this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays.length > 0 &&
        this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays[0].name.includes("*"))
      {
        return (
        <div className='disclaimer text-color'>
          * Partial - Since Inception
        </div>
        );
      }
  }

  render() {    
    if (this.state.entitle === 'default') {
    return (<div></div>);
    } else if(this.state.entitle === 'ALLOW')
    {
    return (
      <div className={"homepage"}>
        <div className={"container"}>

          <div className={"symbol text-color"}>
            {this.state.tickerValue}
          </div>

          <div className={"bid-ask"}>
            <div className={"bid-ask-1"}>
              <div className="bid-ask-row">
                <div className="bid-ask-col1 text-color">
                  Closing Price
              </div>
                <div className="bid-ask-col2 heading-color">
                {this.state.etfBidAskData.ClosingMarketPrice}
              </div>
              </div>
              <div className="bid-ask-row">
                <div className="bid-ask-col1 text-color">
                  Net Asset Value
              </div>
                <div className="bid-ask-col2 heading-color">
                {this.state.etfBidAskData.NAV}
              </div>
              </div>
              <div className="bid-ask-row">
                <div className="bid-ask-col1 text-color">
				          Premium/Discount {this.renderPremiumConfig()}
                </div>
                <div className="bid-ask-col2 heading-color">
                  {this.renderPremiumDiscount()}
                </div>
              </div>

            </div>
            <div className={"bid-ask-2"}>
              <div className="bid-ask-row">
                <div className="bid-ask-col1 text-color">
                  Median Bid-Ask Spread (30 day)
                </div>
                <div className="bid-ask-col2 heading-color">
                {this.state.etfBidAskData.BidAskSpreadPct}
              </div>
              </div>
              <div className="bid-ask-row">
                <div className="bid-ask-col1 text-color">
                  As Of
              </div>
                <div className="bid-ask-col2 heading-color">
                  {this.state.etfBidAskData.AsAtDate}
              </div>
              </div>

            </div>

			      <div className={"chart-div heading-color"}>Premium/Discount {this.renderPremiumConfig()}
                <Chart id={"premDiscountChart"} discountSymbol={this.state.premiumDiscountConfig} />
            </div>
            <div className={"premium-discount"}>
              <table className={"premium-discount-table"}>
                <thead>
                  <tr>
                    <th rowspan="2"><span className={"heading-color"}>{this.state.tickerValue}</span></th>
                    {!this.shouldPrevCalenderDataRender() &&
                    <th><span className={"heading-color"}>{this.state.prevCalenderYearData.year}</span></th>}
                    {this.renderQuarterYearHeading()}
                  </tr>
                  <tr>
                  {!this.shouldPrevCalenderDataRender() && <th scope="col"><span className={"heading-color"}>Calendar Year</span></th>}
                    { this.state.currentCalenderYearQuarterData && this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays
                      ? this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays.map(function(result, index){
						  return <th key={index} scope="col"><span className={"heading-color"}>{result.name}</span></th>
                        })
                      : ''
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span className={"text-color"}>Days Traded at Premium</span></td>
                    {!this.shouldPrevCalenderDataRender() && <td><span className={"text-color"}>{this.state.prevCalenderYearData.premiumTradedDays}</span></td>}
					{ this.state.currentCalenderYearQuarterData && this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays
                      ? this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays.map(function(result, index){
							return <td key={index}><span className={"text-color"}>{result.premiumTradedDays}</span></td>
                        })
                      : ''
                    }
                  </tr>
                  <tr>
                    <td><span className={"text-color"}>Days Traded at Discount</span></td>
                    {!this.shouldPrevCalenderDataRender() && <td><span className={"text-color"}>{this.state.prevCalenderYearData.discTradedDays}</span></td>}
                    { this.state.currentCalenderYearQuarterData && this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays
                      ? this.state.currentCalenderYearQuarterData.currentYearAllQuaterTradedDays.map(function(result, index){
						  return <td key={index}><span className={"text-color"}>{result.discTradedDays}</span></td>
                        })
                      : ''
                    }
                  </tr>
                </tbody>
              </table>
              {this.showDisclaimer()}
            </div>
          </div>
				<div className={"chart-div-2 heading-color"}>Premium/Discount {this.renderPremiumConfig()}
            <DesktopChart id={"desktopPremDiscountChart"} discountSymbol={this.state.premiumDiscountConfig} />
            </div>
          </div>
        </div>
    );
   } else {
    return(
      <div className="header-text">
       <h1>The requested symbol is not currently available on this site.</h1>
       </div>
    );
  }  
  }
}

export default HomePage;
