// This is the root reducer in which all other reducers are registered.
// Naming convention is to use index.js
import { combineReducers } from 'redux';

import deltaOneWidgetReducer from './deltaOneWidgetReducer';

const rootReducer = combineReducers({
	deltaOneWidgetReducer
});

export default rootReducer;
