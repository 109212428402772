import React from 'react';
import { Route, IndexRoute } from 'react-router';

import App from './App';
import Home from './components/home/Container';
import Jump from './components/jump/Container';

export default(
	<Route exact path="/Home/:ticker/:clientId" component={App}>
		<IndexRoute component={Home} />	
		<Route path="/Home/:ticker" component={Home} />
		<Route path="/Jump" component={Jump} />
        <Route path="/" component={Jump} />		
	</Route>
);
