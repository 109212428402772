import { createTypes } from 'reduxsauce';

export default createTypes(`
    SET_ACCESS_TOKEN
    GENERIC_FAILURE
    SET_USER_TYPE
    API_REQUEST_GETCLIENTSTYLE_API
    RECEIVE_SUCCESS_GETCLIENTSTYLE
    API_REQUEST_GETETFHISTORICALDATA_API
    RECEIVE_SUCCESS_GETETFHISTORICALDATA
    API_REQUEST_GETETFPREVCALANDERYEARDATA_API
    RECEIVE_SUCCESS_GETETFPREVCALANDERYEARDATA
    API_REQUEST_GETETFPREVQUARTERDATA_API
    RECEIVE_SUCCESS_GETETFPREVQUARTERDATA
    API_REQUEST_GETETFBIDASKDATA_API
    RECEIVE_SUCCESS_GETETFBIDASKDATA
    API_REQUEST_SUCCESS_GET_CLIENTS_ALL_API
    RECEIVE_SUCCESS_GET_CLIENTS_ALL
    RECEIVE_SUCCESS_GET_ENTITLEMENT
	API_REQUEST_GET_ENTITLEMENT_API
	API_REQUEST_ETP_USER_DATA_API
	RECEIVE_SUCCESS_ETP_USER_DATA
	RECEIVE_FAILURE_GET_ENTITLEMENT
`);