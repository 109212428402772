export const text1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Iaculis eu non diam phasellus vestibulum lorem. Posuere ac ut consequat semper viverra nam libero justo laoreet. A cras semper auctor neque. In dictum non consectetur a erat nam. Id cursus metus aliquam eleifend mi. Mattis pellentesque id nibh tortor id. Tortor id aliquet lectus proin nibh nisl condimentum id venenatis. Massa massa ultricies mi quis hendrerit dolor magna. Vitae justo eget magna fermentum iaculis eu non diam. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Tortor at auctor urna nunc id cursus metus. Egestas pretium aenean pharetra magna ac placerat vestibulum. Sit amet purus gravida quis blandit. At augue eget arcu dictum varius. Non sodales neque sodales ut etiam sit amet. Malesuada pellentesque elit eget gravida cum sociis. Turpis tincidunt id aliquet risus.

Non nisi est sit amet facilisis magna etiam. Urna molestie at elementum eu facilisis sed odio morbi quis. Enim neque volutpat ac tincidunt vitae. Sem viverra aliquet eget sit amet tellus cras. Ipsum a arcu cursus vitae congue. Et netus et malesuada fames ac turpis egestas maecenas. Ultricies tristique nulla aliquet enim. Sapien eget mi proin sed libero. Varius sit amet mattis vulputate enim nulla aliquet. Nisl purus in mollis nunc.

Donec ultrices tincidunt arcu non sodales neque sodales. Vestibulum lectus mauris ultrices eros in cursus turpis. Mattis nunc sed blandit libero volutpat sed. In dictum non consectetur a erat. Lectus nulla at volutpat diam ut. Sociis natoque penatibus et magnis dis parturient montes nascetur. Massa sed elementum tempus egestas sed. Nunc aliquet bibendum enim facilisis gravida. Viverra orci sagittis eu volutpat. Mauris a diam maecenas sed enim ut.

Euismod lacinia at quis risus. Ultricies mi eget mauris pharetra et ultrices neque ornare aenean. Purus faucibus ornare suspendisse sed nisi lacus. Tempor orci dapibus ultrices in iaculis nunc sed. Nibh venenatis cras sed felis eget velit aliquet sagittis id. Libero volutpat sed cras ornare. Sit amet purus gravida quis blandit turpis cursus in hac. A pellentesque sit amet porttitor eget dolor morbi non arcu. Curabitur vitae nunc sed velit dignissim sodales ut eu. Venenatis urna cursus eget nunc scelerisque viverra mauris in. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla.

Amet consectetur adipiscing elit ut. Quis ipsum suspendisse ultrices gravida dictum fusce ut. Accumsan sit amet nulla facilisi morbi tempus iaculis urna id. Risus viverra adipiscing at in tellus integer feugiat scelerisque. Quis risus sed vulputate odio ut enim. Est pellentesque elit ullamcorper dignissim cras. A scelerisque purus semper eget duis. Morbi tempus iaculis urna id volutpat lacus laoreet non. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Amet tellus cras adipiscing enim eu. Eu tincidunt tortor aliquam nulla facilisi cras. In mollis nunc sed id semper. Quis lectus nulla at volutpat diam ut venenatis tellus in. Ornare aenean euismod elementum nisi quis eleifend. Ullamcorper sit amet risus nullam eget felis eget. Tortor dignissim convallis aenean et tortor. Volutpat sed cras ornare arcu dui vivamus arcu.`;

export const text2 = `Donec ultrices tincidunt arcu non sodales neque sodales. Vestibulum lectus mauris ultrices eros in cursus turpis. Mattis nunc sed blandit libero volutpat sed. In dictum non consectetur a erat. Lectus nulla at volutpat diam ut. Sociis natoque penatibus et magnis dis parturient montes nascetur. Massa sed elementum tempus egestas sed. Nunc aliquet bibendum enim facilisis gravida. Viverra orci sagittis eu volutpat. Mauris a diam maecenas sed enim ut.

Euismod lacinia at quis risus. Ultricies mi eget mauris pharetra et ultrices neque ornare aenean. Purus faucibus ornare suspendisse sed nisi lacus. Tempor orci dapibus ultrices in iaculis nunc sed. Nibh venenatis cras sed felis eget velit aliquet sagittis id. Libero volutpat sed cras ornare. Sit amet purus gravida quis blandit turpis cursus in hac. A pellentesque sit amet porttitor eget dolor morbi non arcu. Curabitur vitae nunc sed velit dignissim sodales ut eu. Venenatis urna cursus eget nunc scelerisque viverra mauris in. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla.

Amet consectetur adipiscing elit ut. Quis ipsum suspendisse ultrices gravida dictum fusce ut. Accumsan sit amet nulla facilisi morbi tempus iaculis urna id. Risus viverra adipiscing at in tellus integer feugiat scelerisque. Quis risus sed vulputate odio ut enim. Est pellentesque elit ullamcorper dignissim cras. A scelerisque purus semper eget duis. Morbi tempus iaculis urna id volutpat lacus laoreet non. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Amet tellus cras adipiscing enim eu. Eu tincidunt tortor aliquam nulla facilisi cras. In mollis nunc sed id semper. Quis lectus nulla at volutpat diam ut venenatis tellus in. Ornare aenean euismod elementum nisi quis eleifend. Ullamcorper sit amet risus nullam eget felis eget. Tortor dignissim convallis aenean et tortor. Volutpat sed cras ornare arcu dui vivamus arcu. 

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Iaculis eu non diam phasellus vestibulum lorem. Posuere ac ut consequat semper viverra nam libero justo laoreet. A cras semper auctor neque. In dictum non consectetur a erat nam. Id cursus metus aliquam eleifend mi. Mattis pellentesque id nibh tortor id. Tortor id aliquet lectus proin nibh nisl condimentum id venenatis. Massa massa ultricies mi quis hendrerit dolor magna. Vitae justo eget magna fermentum iaculis eu non diam. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Tortor at auctor urna nunc id cursus metus. Egestas pretium aenean pharetra magna ac placerat vestibulum. Sit amet purus gravida quis blandit. At augue eget arcu dictum varius. Non sodales neque sodales ut etiam sit amet. Malesuada pellentesque elit eget gravida cum sociis. Turpis tincidunt id aliquet risus.

Non nisi est sit amet facilisis magna etiam. Urna molestie at elementum eu facilisis sed odio morbi quis. Enim neque volutpat ac tincidunt vitae. Sem viverra aliquet eget sit amet tellus cras. Ipsum a arcu cursus vitae congue. Et netus et malesuada fames ac turpis egestas maecenas. Ultricies tristique nulla aliquet enim. Sapien eget mi proin sed libero. Varius sit amet mattis vulputate enim nulla aliquet. Nisl purus in mollis nunc.`;

export const text3 = `Non nisi est sit amet facilisis magna etiam. Urna molestie at elementum eu facilisis sed odio morbi quis. Enim neque volutpat ac tincidunt vitae. Sem viverra aliquet eget sit amet tellus cras. Ipsum a arcu cursus vitae congue. Et netus et malesuada fames ac turpis egestas maecenas. Ultricies tristique nulla aliquet enim. Sapien eget mi proin sed libero. Varius sit amet mattis vulputate enim nulla aliquet. Nisl purus in mollis nunc.

Donec ultrices tincidunt arcu non sodales neque sodales. Vestibulum lectus mauris ultrices eros in cursus turpis. Mattis nunc sed blandit libero volutpat sed. In dictum non consectetur a erat. Lectus nulla at volutpat diam ut. Sociis natoque penatibus et magnis dis parturient montes nascetur. Massa sed elementum tempus egestas sed. Nunc aliquet bibendum enim facilisis gravida. Viverra orci sagittis eu volutpat. Mauris a diam maecenas sed enim ut.

Euismod lacinia at quis risus. Ultricies mi eget mauris pharetra et ultrices neque ornare aenean. Purus faucibus ornare suspendisse sed nisi lacus. Tempor orci dapibus ultrices in iaculis nunc sed. Nibh venenatis cras sed felis eget velit aliquet sagittis id. Libero volutpat sed cras ornare. Sit amet purus gravida quis blandit turpis cursus in hac. A pellentesque sit amet porttitor eget dolor morbi non arcu. Curabitur vitae nunc sed velit dignissim sodales ut eu. Venenatis urna cursus eget nunc scelerisque viverra mauris in. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla.

Amet consectetur adipiscing elit ut. Quis ipsum suspendisse ultrices gravida dictum fusce ut. Accumsan sit amet nulla facilisi morbi tempus iaculis urna id. Risus viverra adipiscing at in tellus integer feugiat scelerisque. Quis risus sed vulputate odio ut enim. Est pellentesque elit ullamcorper dignissim cras. A scelerisque purus semper eget duis. Morbi tempus iaculis urna id volutpat lacus laoreet non. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Amet tellus cras adipiscing enim eu. Eu tincidunt tortor aliquam nulla facilisi cras. In mollis nunc sed id semper. Quis lectus nulla at volutpat diam ut venenatis tellus in. Ornare aenean euismod elementum nisi quis eleifend. Ullamcorper sit amet risus nullam eget felis eget. Tortor dignissim convallis aenean et tortor. Volutpat sed cras ornare arcu dui vivamus arcu. 

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Iaculis eu non diam phasellus vestibulum lorem. Posuere ac ut consequat semper viverra nam libero justo laoreet. A cras semper auctor neque. In dictum non consectetur a erat nam. Id cursus metus aliquam eleifend mi. Mattis pellentesque id nibh tortor id. Tortor id aliquet lectus proin nibh nisl condimentum id venenatis. Massa massa ultricies mi quis hendrerit dolor magna. Vitae justo eget magna fermentum iaculis eu non diam. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Tortor at auctor urna nunc id cursus metus. Egestas pretium aenean pharetra magna ac placerat vestibulum. Sit amet purus gravida quis blandit. At augue eget arcu dictum varius. Non sodales neque sodales ut etiam sit amet. Malesuada pellentesque elit eget gravida cum sociis. Turpis tincidunt id aliquet risus.`;

export const DISCOUNT_IN_PERCENTAGE = 'pct';