import { connect } from 'react-redux';

import Home from './HomePage';

import * as actions from '../../actions/deltaOneWidgetActions.js';

function mapStateToProps(state) {
	return {
		token: state.deltaOneWidgetReducer.accessToken,
		// GetClientStyle
		clientState: state.deltaOneWidgetReducer.clientStyle,
		// GetETFData
		chartHistoricalData: state.deltaOneWidgetReducer.chartHistoricalData,
		prevCalenderYearData: state.deltaOneWidgetReducer.prevCalenderYearData,
		currentCalenderYearQuarterData: state.deltaOneWidgetReducer.currentCalenderYearQuarterData,
		// getEtfBidAskData
		etfBidAskData: state.deltaOneWidgetReducer.etfBidAskData,
		// getEntitlement
		entitle: state.deltaOneWidgetReducer.entitlement,
		etpUserData: state.deltaOneWidgetReducer.etpUserData,
		errorCode: state.deltaOneWidgetReducer.errorCode
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setAccessToken: (token) => {
			return dispatch(actions.setAccessToken(token));
		},
		//getEntitlement
		requestGetEntitlement: (etpTicker) => {
			return dispatch(actions.requestGetEntitlement(etpTicker))
		},
		// getClientStyle
		requestGetClientStyleApi: (userTierId) => {
			return dispatch(actions.requestGetClientStyle(userTierId))
		},
		// getEtfData
		requestGetEtfHistoricalData: (etpTicker, premiumDiscountConfig) => {
			return dispatch(actions.requestGetEtfHistoricalData(etpTicker, premiumDiscountConfig))
		},
		requestGetEtfPrevCalenderYearData: (etpTicker, premiumDiscountConfig) => {
			return dispatch(actions.requestGetEtfPrevCalenderYearData(etpTicker, premiumDiscountConfig))
		},
		requestGetEtfPrevQuarterData: (etpTicker, premiumDiscountConfig) => {
			return dispatch(actions.requestGetEtfPrevQuarterData(etpTicker, premiumDiscountConfig))
		},
		// getEtfBidAskData
		requestGetEtfBidAskData: (etpTicker) => {
			return dispatch(actions.requestGetEtfBidAskData(etpTicker))
		},
		requestEtpUserData: () => {
			return dispatch(actions.requestEtpUserData());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
