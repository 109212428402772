import { connect } from 'react-redux';

import Jump from './JumpPage';

import * as actions from '../../actions/deltaOneWidgetActions.js';

function mapStateToProps(state) {
	return {
		token: state.deltaOneWidgetReducer.accessToken,
		clientName: state.deltaOneWidgetReducer.clientName,
		allClients: state.deltaOneWidgetReducer.allClients
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestGetClientsApi: (userTierId) => {
			return dispatch(actions.requestGetClientsApi(userTierId));
		},
		requestGetClientsAllApi: () => {
			return dispatch(actions.requestGetClientsAllApi());
		},
		setAccessToken: (token) => {
			return dispatch(actions.setAccessToken(token));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Jump);
