import LocalApiService from '../../src/services/local-api-service';
import axios from 'axios';

export const getToken = (userTier) => {
	let username5854 = null;
	let password5854 = null;
	let username5858 = null;
	let password5858 = null;
	let username5877 = null;
	let password5877 = null;
	let username5972 = null;
	let password5972 = null;
	let apiDetails = null;
	let api = LocalApiService.getEndpointsPromise()
		.then(responseEndpoints => {
			return {
				tokenApiUrl: responseEndpoints.data.tokenApiUrl,
				username5854: responseEndpoints.data.username5854,
				password5854: responseEndpoints.data.password5854,
				username5858: responseEndpoints.data.username5858,
				password5858: responseEndpoints.data.password5858,
				username5877: responseEndpoints.data.username5877,
				password5877: responseEndpoints.data.password5877,
				username5972: responseEndpoints.data.username5972,
				password5972: responseEndpoints.data.password5972
			};
		})
		.then(apiDetails => {
			let auth = '';
			if (userTier === 5854) {
				auth = {  //5854
					username: apiDetails.username5854,
					password: apiDetails.password5854
				};
			} else if (userTier === 5858) {
				auth = {  //5858
					username: apiDetails.username5858,
					password: apiDetails.password5858
				};
			} else if (userTier === 5877) {
				auth = {  //5877
					username: apiDetails.username5877,
					password: apiDetails.password5877
				};
			} else if (userTier === 5972) {
				auth = {  //5972
					username: apiDetails.username5972,
					password: apiDetails.password5972
				};
			}

			const data = 'grant_type=client_credentials';
			axios.post(apiDetails.tokenApiUrl, data, { auth })
			.then(response => {
			let token = '';
			if (response.data.access_token) {
				token = response.data.access_token;
			}
			localStorage.setItem('access_token', token);
			})
			.catch(error => {
			console.log(error);
			});
		})
};

export const getApiUrl  = () => {
	const currentURL = window.location.href;
	if(currentURL.includes('localhost'))
		return 'http://localhost:3000/deltaone-6c11-api/v1/docs';
	else if(currentURL.includes('.dev.'))
		return 'https://dev-api.markitdigital.com/deltaone-6c11-api/v1/';
	else if(currentURL.includes('.qa') || currentURL.includes('.markitqa.'))
		return 'https://qa-api.markitdigital.com/deltaone-6c11-api/v1/';
	else
		return 'https://api.markitdigital.com/deltaone-6c11-api/v1/';
}
