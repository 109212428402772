import Types from "../actions/types";
import { createReducer } from "reduxsauce";

import INITIAL_STATE from "./initial-state/deltaOneWidget";

const setAccessToken = (state, action) =>
  Object.assign({}, state, { accessToken: action.accessToken });

const genericApifailure = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    errorCode: action.data
  });

const setUserType = (state, result) => {
  return Object.assign({}, state, {
    userType: result
  });
};

const receiveSuccessGetClientStyleApi = (state, result) => {
  return Object.assign({}, state, {
    clientStyle: result.response.data
  });
};
const receiveSuccessGetClientsAllApi = (state, result) => {
  return Object.assign({}, state, {
    allClients: result.response.data
  });
};
const receiveSuccessGetEntitlementApi = (state, result) => {
  return Object.assign({}, state, {
	entitlement: result.response.data,
	errorCode: 200
  });
};
const receiveFailureGetEntitlementApi = (state, result) => {
	return Object.assign({}, state, {
		errorCode: 403
	});
};

const receiveSuccessGetEtfHistoricalData = (state, result) => {
  return Object.assign({}, state, {
    chartHistoricalData: result.response.data
  });
}

const receiveSuccessGetEtfPrevCalenderYearData = (state, result) => {
  return Object.assign({}, state, {
    prevCalenderYearData: result.response.data
  });
}

const receiveSuccessGetEtfPrevQuarterData = (state, result) => {
  return Object.assign({}, state, {
    currentCalenderYearQuarterData: result.response.data
  });
}

const receiveSuccessGetEtfBidAskDataApi = (state, result) => {
  return Object.assign({}, state, {
    etfBidAskData: result.response.data
  });
}

const receiveSuccessETPUserData = (state, action) => {
	return Object.assign({}, state, {
		etpUserData: action.response.data 
	});
}

const ACTION_HANDLERS = {
  [Types.SET_ACCESS_TOKEN]: setAccessToken,
  [Types.GENERIC_FAILURE]: genericApifailure,
  [Types.SET_USER_TYPE]: setUserType,
  [Types.RECEIVE_SUCCESS_GETCLIENTSTYLE]: receiveSuccessGetClientStyleApi,
  [Types.RECEIVE_SUCCESS_GETETFHISTORICALDATA]: receiveSuccessGetEtfHistoricalData,
  [Types.RECEIVE_SUCCESS_GETETFPREVCALANDERYEARDATA]: receiveSuccessGetEtfPrevCalenderYearData,
  [Types.RECEIVE_SUCCESS_GETETFPREVQUARTERDATA]: receiveSuccessGetEtfPrevQuarterData,
  [Types.RECEIVE_SUCCESS_GETETFBIDASKDATA]: receiveSuccessGetEtfBidAskDataApi,
  [Types.RECEIVE_SUCCESS_GET_ENTITLEMENT]: receiveSuccessGetEntitlementApi,
  [Types.RECEIVE_SUCCESS_GET_CLIENTS_ALL]: receiveSuccessGetClientsAllApi,
  [Types.RECEIVE_SUCCESS_ETP_USER_DATA]: receiveSuccessETPUserData,
  [Types.RECEIVE_FAILURE_GET_ENTITLEMENT]: receiveFailureGetEntitlementApi
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
