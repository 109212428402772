import moment from 'moment-timezone';
import numeral from 'numeral';

const TEXT_NULL_VALUE = '--';

function isNull(val) {
	return typeof val === 'undefined' || val === null || val === '-32768' || val === -32768;
}

export const getQueryString = (name) => {
	var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href)
	if (results == null) {
	  return null
	} else {
	  return decodeURI(results[1]) || 0
	}
  }

function numberFormatter(val, formatString) {
	return isNull(val)
		? TEXT_NULL_VALUE
		: numeral(val).format(formatString);
}

export const isNullValue = val => {
	return isNull(val);
};

export const date = (value, format) => {
	const now = moment();
	const time = moment(value);
	const timeUtc = moment.tz(moment(value).toISOString(), 'America/New_York');

	// parseZone allows you to keep the zone the timestamp has on it
	// moment will automatically parse into your local

	if (time.isValid()) {
		switch (format) {
			case 'zoneTime':
				return time.parseZone().format('HH:mm');
			case 'localTime':
				return time.format('HH:mm');
			case 'countdown': {
				const diff = time.diff(now);
				const duration = moment.duration(diff);
				const hours = Math.floor(duration.asHours());
				return hours + moment.utc(diff).format(':mm:ss');
			}
			case 'roadshow':
				if (now.isSame(time, 'day')) {
					return `Today ${time.parseZone().format('DD MMM')}`;
				}
				return time.parseZone().format('ddd DD MMM');
			case 'dayMonth':
				if (now.isSame(time, 'day')) {
					return time.format('HH:mm');
				}
				return time.format('DD MMM');
			case 'dayMonthYearZone': {
				const zone = moment.tz.zone(moment.tz.guess());
				const zoneTime = moment.tz(time, zone.name);
				let timeAbbreviation = zone.abbr(time.format('X'));
				timeAbbreviation = ((timeAbbreviation[0] === '+' || timeAbbreviation === '-') ? 'GMT' : '') + timeAbbreviation;
				return `${zoneTime.format('DD MMMM, YYYY HH:mm')} ${timeAbbreviation}`;
			}
			case 'monthDayYear':
				return timeUtc.format('MM/DD/YYYY');
			case 'monthDayYearTimeET':
				return `${timeUtc.format('MM/DD/YYYY hh:mma')} ET`;
			case 'timeET':
				return `${timeUtc.format('hh:mma')} EST `;
			case 'MMMDDYYYY':
				return time.format('MMM DD, YYYY');
			case 'DDMMMYYYY':
				return time.format('DD MMM, YYYY');
			default:
				if (now.isSame(time, 'day')) {
					return time.format('HH:mm');
				}
				return time.format('DD MMM, YYYY');
		}
	} else {
		return TEXT_NULL_VALUE;
	}
};

export const dateET = value => date(value, 'monthDayYearTimeET');

export const timeET = value => date(value, 'timeET');

export const dateNoTime = value => date(value, 'monthDayYear');

export const dateMonthDateYear = value => date(value, 'MMMDDYYYY');

export const dtDateMonthYear = value => date(value, 'DDMMMYYYY');

export const dateCountdown = value => date(value, 'countdown');

export const dateAsOfET = value => {
	return isNull(value) ? TEXT_NULL_VALUE : `As of ${timeET(value)}${dateNoTime(value)}`;
};

export const dateOfET = value => {
	return isNull(value) ? TEXT_NULL_VALUE : timeET(value) + dateNoTime(value);
};


export const dateAsOfCloseET = value => {
	return `as of close ${dateET(value)}`;
};

export const dateNoTimeAsOf = value => {
	return `as of ${dateNoTime(value)}`;
};

export const dateNoTimeAsOfClose = value => {
	return `as of close ${dateNoTime(value)}`;
};

export const number = (val, precision = 0) => {
	const formatString = `0.${Array(precision + 1).join('0')}`;
	return numberFormatter(val, formatString);
};

export const numberWithoutSign = (val, precision = 0) => {
	const formatString = `0.${Array(precision + 1).join('0')}`;
	if (val !== null) {
		return numberFormatter(val, formatString).replace('-', '');
	}
	return numberFormatter(val, formatString);
};
export const numberComma = (val, precision = 0) => {
	const formatString = `0,0[.]${Array(precision + 1).join('0')}`;
	return numberFormatter(val, formatString);
};

export const numberCommaSigned = (val, precision) => {
	const startChar = val !== 0 ? '+' : '';
	const formatString = `${startChar}0,0.${Array(precision + 1).join('0')}`;
	return numberFormatter(val, formatString);
};

export const numberMagnitude = (val, precision = 0) => {
	const formatString = `0[.]${Array(precision + 1).join('0')}a`;
	return numberFormatter(val, formatString).toUpperCase();
};

export const numberSigned = (val, precision = 0) => {
	const startChar = val !== 0 ? '+' : '';
	const formatString = `${startChar}0.${Array(precision + 1).join('0')}`;
	return numberFormatter(val, formatString).replace('-', '');
};

export const percent = (val, precision = 0) => {
	const formatString = `0.${Array(precision + 1).join('0')}`;
	return isNull(val)
		? TEXT_NULL_VALUE
		: `${numberFormatter(val, formatString)}%`;
};

export const percentNoSign = (val, precision = 0) => {
	const formatString = `0.${Array(precision + 1).join('0')}`;
	return numberFormatter(val, formatString);
};

export const percentComma = (val, precision = 0) => {
	const formatString = `0,0[.]${Array(precision + 1).join('0')}%`;
	return numberFormatter(val, formatString);
};

export const percentCommaSigned = (val, precision) => {
	const startChar = val !== 0 ? '+' : '';
	const formatString = `${startChar}0,0.${Array(precision + 1).join('0')}%`;
	return numberFormatter(val, formatString);
};

export const percentSigned = (val, precision = 0) => {
	const startChar = val > 0 ? '+' : '';
	const formatString = Math.round(val) <= 10 ? `00.${Array(precision + 1).join('0')}` : `0.${Array(precision + 1).join('0')}`;
	return `${startChar + numberFormatter(val, formatString)}%`;
};

export const price = (val, precision = 2) => {
	return isNull(val)
		? TEXT_NULL_VALUE
		: `$${number(val, precision).replace('-', '')}`;
};

export const priceComma = (val, precision = 2) => {
	return isNull(val)
		? TEXT_NULL_VALUE
		: `$${numberComma(val, precision)}`;
};

export const priceMagnitude = (val, precision = 0) => {
	return isNull(val)
		? TEXT_NULL_VALUE
		: `$${numberMagnitude(val, precision)}`;
};

export const text = val => {
	return isNull(val) ? TEXT_NULL_VALUE : val;
};

export const textPluralize = (val, count, pluralCharacters) => {
	if (typeof count !== 'number' || count <= 0 || count > 1) {
		pluralCharacters = pluralCharacters || 's';
		return val + pluralCharacters;
	}

	return val;
};

var DAY_MILLISECONDS = 86400000,
    MS_DAY_OFFSET = 25569;

export function convertToOADate(fromDate) { 
	return moment(fromDate).toOADate();
}

moment.fn.toOADate = function(jsDateInput){
let jsDate = jsDateInput || this._d || new Date();
let timezoneOffset = jsDate.getTimezoneOffset() / (60 * 24);
let msDateObj = ( jsDate.getTime() / DAY_MILLISECONDS ) + (MS_DAY_OFFSET - timezoneOffset);
return msDateObj;
}

export function formatOADate(msDate, format) {
	if (!msDate) {
			return "";
	}
	let jO = new Date(((msDate - MS_DAY_OFFSET) * DAY_MILLISECONDS));
	let tz = jO.getTimezoneOffset();
	jO = new Date(((msDate - MS_DAY_OFFSET + (tz / (60 * 24))) * DAY_MILLISECONDS));

	return moment(jO).format(format);
}
