const INITIAL_STATE = {
	fetching: true,
	clientStyle: [],
	chartHistoricalData: {
		history: [],
      	discTradedDays: -1,
      	premiumTradedDays: -1,
      	year: -1,
      	currentYearAllQuaterTradedDays: []
	},
	prevCalenderYearData: {
		history: [],
      	discTradedDays: -1,
      	premiumTradedDays: -1,
      	year: -1,
      	currentYearAllQuaterTradedDays: []
	},
	currentCalenderYearQuarterData: {
		history: [],
      	discTradedDays: -1,
      	premiumTradedDays: -1,
      	year: -1,
      	currentYearAllQuaterTradedDays: []
	},
	etfBidAskData: [],
	allClients: []
};
export default INITIAL_STATE;
