import React from 'react';
import * as JumpPageStyle from '../../styles/JumpPage.scss'
import {getToken} from '../../utils/helper'
class JumpPage extends React.Component {
  constructor(props) {
    super(props);  
    let n = window.location.href.lastIndexOf("/");
    var url =  window.location.href.substring(0,n+1);
    this.state = {
        ticker: "TMF",
        clientId: 5858,
        width: 500,
        envUrl: url + "home/TMF/5858",
        iFrameWidth: 500,
        iFrameUrl: url + "home/TMF/5858"
        
    };
    this.handleSubmit = this.handleSubmit.bind(this);       
  }

  componentDidMount() {
    this.props.requestGetClientsAllApi();    
    window.addEventListener("message", function (e) {
      const MARKIT_ORIGIN = ['https://deltaone-6c11-a2.markitqa.com','https://deltaone-6c11.markitqa.com','https://deltaone-6c11.markitondemand.com','https://deltaone-6c11.dev.services.mdgapp.net','https://deltaone-6c11.qa.services.mdgapp.net','https://deltaone-6c11.ctc.services.mdgapp.net','https://deltaone-6c11.ptc.services.mdgapp.net','http://localhost:3000'];
        if (MARKIT_ORIGIN.includes(e.origin)) {
          try {
            const data = JSON.parse(e.data);
            document.querySelector('#iframeId').style.height = data.docHeight + 'px';
            document.querySelector('#iframeheight').innerHTML =  data.docHeight.toFixed(0);           
          } catch (err) {
          }
        }
      }, false);
  }
  handleChange = (event) => {
    this.setState({width:event.target.value});   
  }
  handleClientChange = (event) => {
    this.setState({clientId:event.target.value});
  }
  handleTickerChange = (event) => {
    this.setState({ticker:event.target.value});
  }
  handleSubmit = (event) => {   
    let url = this.state.envUrl;
    let n = url.lastIndexOf("/home");
    let newUrl = url.substr(0,n+5);        
    this.setState({
        iFrameWidth: this.state.width, iFrameUrl: newUrl + "/"+this.state.ticker+"/"+this.state.clientId        
      }, () => {
        document.getElementById('iframeId').src = newUrl + "/"+this.state.ticker+"/"+this.state.clientId
        document.getElementById('iframeId').contentWindow.location.reload(true);        
      });
    getToken(parseInt(this.state.clientId));
  }

  render() {
    return (   
        <div className="jumpPage">
            <div className="container">
                <div className="header-text">                
                DeltaOne-6c11 Widget
                </div>
                <div className="sub-header-row">
                    <div className="section1">
                        <span className="right-margin">
                            Client
                        </span>
                        <span>
                        <select value={this.state.clientId} onChange={this.handleClientChange}>
                        {this.props.allClients && this.props.allClients.map((element) =>
                            <option value={element.UserTierId}>{element.ClientName}</option> 
                        )}
                        </select>
                        </span>
                    </div>
                    <div className="section">
                        <span className="right-margin">Ticker</span>
                        <span><input id="ticker" class="width" type="text"  value={this.state.ticker} onChange={this.handleTickerChange} /></span>
                    </div>
                    <div className="section2">
                        <span className="right-margin">Width(in px)</span>
                        <span><input id="iframeWidth" class="width" type="text" value={this.state.width} onChange={this.handleChange} /></span>
                    </div>
                    <div className="section1">
                        <span className="right-margin">Height(in px)</span>
                        <label id="iframeheight" class="width"></label>
                    </div>
                    <div className="section1buttton">
                        <button className="btn" onClick={this.handleSubmit}>SUBMIT</button> 
                    </div>
                </div>
            </div>
            <div class="container2">
				<iframe id="iframeId" width={this.state.iFrameWidth + "px"} height="1000px" src={this.state.iFrameUrl + "?access_token="+localStorage.getItem('access_token')} frameborder="1"></iframe>
			</div>
    </div>
    );
  }
}

export default JumpPage;