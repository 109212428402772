import React from 'react';
import * as AppStyle from './styles/App.scss'
import { PropTypes } from 'prop-types'

export default class App extends React.Component {
	constructor(props) {
	  super(props);
		
	}
	render () {
	  return (
  
		<div>
		  {this.props.children}
  
		</div>
  
	  )
	};
  };
