import Modcharts from './shared/modCharts';
import React from 'react';
import * as ModChartCss from '../styles/ModChart.scss'
import $ from 'jquery';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import msdate from 'moment-msdate'
import moment from 'moment'
import ChartCss from '../styles/Chart.scss'
import {DISCOUNT_IN_PERCENTAGE} from '../constants'
class Chart extends React.Component {

constructor(props) {
    super(props);
    this.hex2rgba = this.hex2rgba.bind(this)
}

static propTypes = {
    chartHistoricalData: PropTypes.object,
    clientStyle: PropTypes.object,
    discountSymbol: PropTypes.string
}

hex2rgba(hex, alpha)
{
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

componentWillReceiveProps(nextProps) {
    if (nextProps.chartHistoricalData && nextProps.chartHistoricalData.history && nextProps.chartHistoricalData.history.length > 0) {
        const premDiscountChart = new Modcharts('#premDiscountChart');

        premDiscountChart.crosshairDataCallback = function (data) {
            var keyDate = moment(msdate.fromOADate(data["date"])).format("MM/DD/YYYY");
            var discountIn = $("#chartPremiumDiscntValue").attr("symbol");
            var discountSymbol = '';
            if (discountIn == DISCOUNT_IN_PERCENTAGE) {
                discountSymbol = "%";
            }
            $("#lblChartDate").text("Date");
            $("#chartDateValue").text(keyDate);
            $("#lblChartPremiumDiscnt").text("Premium/Discount");
            $("#chartPremiumDiscntValue").text((data["value"]).toFixed(2) + discountSymbol);
            $('.modcharts-crosshair-horiz').css('display', 'none');
        };

        var historicalData = nextProps.chartHistoricalData && nextProps.chartHistoricalData.history ? nextProps.chartHistoricalData.history : [];
        var filteredHistoricalData = [];
        for(var i = 0; i < historicalData.length; i++) {
            var obj = historicalData[i];
            filteredHistoricalData.push({
                date: obj.AsAtDate,
                value: obj.PremiumDiscountPercent
            });
        }

        if (filteredHistoricalData && filteredHistoricalData.length > 0)
        {
            premDiscountChart.load({
                params: {
                    // animation: $('#animation').is(':checked') ? "all" : null,
                    crosshairEnabled: true,
                    crosshairPersist: true,
                    customData: {
                        "mydata": filteredHistoricalData
                    }
                },
                panels: [{
                // params:{
                //     yAxisFormat: "percent",
                //     yAxisRange: [-100, 100]
                // },
                indicators: [{
                    id: "custom",
                    style: {
                        lineColor: nextProps.clientStyle[0] && nextProps.clientStyle[0].StyleConfig ? this.hex2rgba(nextProps.clientStyle[0].StyleConfig.substr(2, 7), 1) : '',
                    },
                    markerType: "line",
                    datasetId: "mydata"
                    }]
                }]
            });
        }
    }
}

render() {
    return ( 
        <div className="premium-discount-heading"> 
            <span id="lblChartDate" className="date-hover-class text-color" />
            <span id="chartDateValue" className="date-discount-value-class heading-color" />
            <span id="lblChartPremiumDiscnt" className="premDiscount-hover-class text-color" />
            <span id="chartPremiumDiscntValue" className="date-discount-value-class heading-color" symbol={this.props.discountSymbol} />
            <div id="premDiscountChart" ></div>
        </div>);
    }
}

function mapStateToProps (state) {
    return {
      chartHistoricalData: state.deltaOneWidgetReducer.chartHistoricalData,
      clientStyle: state.deltaOneWidgetReducer.clientStyle
    }
}
  
export default connect(mapStateToProps)(Chart);